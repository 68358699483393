import Vue from "vue";
import App from "./App.vue";
import ElementUI from "element-ui";

import "element-ui/lib/theme-chalk/index.css";

import Pagination from "@/components/Pagination";

//sm2加密
import { sm2Encryption, sm2Decrypt } from "@/utils/index";

Vue.prototype.sm2Encryption = sm2Encryption;
Vue.prototype.sm2Decrypt = sm2Decrypt;

Vue.component("Pagination", Pagination);

// 路由
import router from "./router";
// Vuex
import store from "./store";
// echarts图标
Vue.config.productionTip = false;

Vue.use(ElementUI);

Vue.prototype.$bus = new Vue();

// 全局组件挂载
// Vue.component('DictTag', DictTag)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
