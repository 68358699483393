import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: '',
    redirect: 'home',
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/specialDashboard/dashboard/index.vue"),
  },
];

const router = new VueRouter({
  // mode: 'xfdp',
  scrollBehavior: () => ({ y: 0 }),
  // base:'lndx',
  routes,
});
//全局前置路由守卫————初始化的时候被调用、每次路由切换之前被调用
router.beforeEach((to, form, next) => {
  if (to.path == "/login") {
    localStorage.removeItem("token");
  }
  let user = localStorage.getItem("token");
  if (!user && to.path != "/login") {
    next({ path: "/login" });
  } else {
    next();
  }
});

export default router;
