import JSEncrypt from "jsencrypt/bin/jsencrypt.min";
const sm2 = require("sm-crypto").sm2;
// 密钥对生成 http://web.chacuo.net/netrsakeypair

const publicKey =
  "MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAKoR8mX0rGKLqzcWmOzbfj64K8ZIgOdH\n" +
  "nzkXSOVOZbFu/TJhZ7rFAN+eaGkl3C4buccQd/EjEsj9ir7ijT7h96MCAwEAAQ==";

const privateKey =
  "MIIBVAIBADANBgkqhkiG9w0BAQEFAASCAT4wggE6AgEAAkEAqhHyZfSsYourNxaY\n" +
  "7Nt+PrgrxkiA50efORdI5U5lsW79MmFnusUA355oaSXcLhu5xxB38SMSyP2KvuKN\n" +
  "PuH3owIDAQABAkAfoiLyL+Z4lf4Myxk6xUDgLaWGximj20CUf+5BKKnlrK+Ed8gA\n" +
  "kM0HqoTt2UZwA5E2MzS4EI2gjfQhz5X28uqxAiEA3wNFxfrCZlSZHb0gn2zDpWow\n" +
  "cSxQAgiCstxGUoOqlW8CIQDDOerGKH5OmCJ4Z21v+F25WaHYPxCFMvwxpcw99Ecv\n" +
  "DQIgIdhDTIqD2jfYjPTY8Jj3EDGPbH2HHuffvflECt3Ek60CIQCFRlCkHpi7hthh\n" +
  "YhovyloRYsM+IS9h/0BzlEAuO0ktMQIgSPT3aFAgJYwKpqRYKlLDVcflZFCKY7u3\n" +
  "UP8iWi1Qw0Y=";

// 加密
export function encrypt(txt) {
  const encryptor = new JSEncrypt();
  encryptor.setPublicKey(publicKey); // 设置公钥
  return encryptor.encrypt(txt); // 对数据进行加密
}

// 解密
export function decrypt(txt) {
  const encryptor = new JSEncrypt();
  encryptor.setPrivateKey(privateKey); // 设置私钥
  return encryptor.decrypt(txt); // 对数据进行解密
}

//sm2加密
const publicKeySm2 =
  "04E2D048CDC43AAFCD2844CA94F106681B62327A0840212998ED9A4552B0C0C29D7765F7F93ECEEE8D2F0101C6A8B2AD42066431AB2DE5B2DE84CD3F42888883A8";

const privateKeySm2 =
  "0FB3AEBE91366DED03246AFB30813B39D3A2FD5A87AE790C6A385E98A15240CF";
/**
 * sm2加密
 * @param {*} value 要加密的value
 * @return 加密后的密文
 */
export function localSm2Encryption(value) {
  // 公钥

  let encryptData = value && sm2.doEncrypt(value, publicKeySm2, 1);
  // 加密的数据前面要拼接04
  return encryptData ? "" + encryptData : null;
}
/**
 * sm2解密
 * @param {*} value 要解密的value
 * @return 解密后的 名文
 */
export function localSm2Decrypt(value) {
  // 私钥
  let decryptData = value && sm2.doDecrypt(value, privateKeySm2, 1);
  // 解密的数据
  return decryptData ? decryptData : null;
}
