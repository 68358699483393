import axios from "axios";
import router from '@/router'
import { sm2Encryption, sm2Decrypt } from "@/utils/index";
import { Notification, MessageBox, Message, Loading } from 'element-ui'
// 创建实例
axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 10000,
});

// 请求拦截器
instance.interceptors.request.use(
  (config) => {
    // console.log('------------config1', config)
    //sm2传参加密  production    development
    let configData = JSON.parse(JSON.stringify(config));

    const { params, data } = configData;
    // console.log('------------config2', data)

    // if (
    //   JSON.stringify(config.data) !== "{}" &&
    //   process.env.NODE_ENV === "production"
    // ) {
    //   config.headers["encrpt-mode"] = "sm2-rj";
    //   config.data = { datas: sm2Encryption(data) };
    //   // console.log('------------config3', sm2Decrypt(config.data.datas))
      
    // }
    // if (
    //   JSON.stringify(config.params) !== "{}" &&
    //   !(params && Object.prototype.hasOwnProperty.call(params, "tables")) &&
    //   process.env.NODE_ENV === "production"
    // ) {
    //   config.headers["encrpt-mode"] = "sm2-rj";

    //   config.params = { params: sm2Encryption(params) };
    // }



    config.headers["Authorization"] = localStorage.getItem("token");
    // console.log('------------config3', config.data)
    // console.log('------------config3', sm2Decrypt(config.data.datas))
    return config;
  },
  (err) => {
    // return Promise.reject(err);
    return  Message({ message: err, type: 'error' })
  }
);

// 响应拦截器
instance.interceptors.response.use(
  (res) => {

     //解密
    let resData = JSON.parse(JSON.stringify(res));
    const { data } = resData;
    // console.log('----responsedata', data)

    if (Object.prototype.toString.call(data) === "[object String]") {
      res.data = JSON.parse(sm2Decrypt(data.substr(2)));
    }

    //  console.log(res, "---------------res");
    const code = res.data.code || 200;
      // 获取错误信息
    const msg =  res.data.msg 
    if (code === 401) {
      Message({ message: msg, type: 'warning' })
      router.push('/login')
      return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
    } else if (code === 500) {
      Message({ message: msg, type: 'error' })
      return Promise.reject(new Error(msg))
    } else if (code === 601) {
      Message({ message: msg, type: 'warning' })
      return Promise.reject('error')
    } else if (code !== 200) {
      Notification.error({ title: msg })
      return Promise.reject('error')
    } else {
      return res.data
    }

  
  },
  (error) => {
    // console.log('err' + error)
    let { message } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    Message({ message: message, type: 'error', duration: 5 * 1000 })
    return Promise.reject(error)
  }
);


// 导出实例
export default instance;