import request from '@/utils/request'

export function Login(body) {
  return request({
    url: "/lightindustry/login",
    method: "post",
    data: body,
  });
}
// 获取验证码
export function getCodeImgApi() {
  return request({
    url: "/code",
    headers: {
      // 携带 Token
      isToken: false,
    },
    method: "get",
    timeout: 20000,
  });
}

// 注册方法
export function register(data) {
  return request({
    url: "/lightindustry/register",
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 获取用户详细信息
export function getInfo() {
  return request({
    url: "/lightindustry/getInfo",
    method: 'get'
  })
}

// 退出方法
export function logout() {
  return request({
    url: "/logout",
    method: "post",
  })
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: "/lightindustry/captchaImage",
    headers: {
      isToken: false
    },
    method: 'get',
    timeout: 20000
  })
}