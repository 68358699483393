import { localSm2Decrypt } from "@/utils/jsencrypt";
const sm2 = require("sm-crypto").sm2;

/**
 * sm2加密
 * @param {*} value 要加密的value
 * @return 加密后的密文
 */
export function sm2Encryption(value) {
  let enData = JSON.stringify(value);

  // 公钥
  const publicKey =
    "0458AFE4B512D7D16D3467492325A884E2CF143A1441670CD83608BFBB614335C5C09C41891A6E0880758A31B2D400DD924DF672F731CE7E290CC1C25D0F912429";
  let encryptData = enData && sm2.doEncrypt(enData, publicKey, 1);

  // 加密的数据前面要拼接04
  return encryptData ? "04" + encryptData : null;
}
/**
 * sm2解密
 * @param {*} value 要解密的value
 * @return 解密后的 名文
 */
export function sm2Decrypt(value) {
  // 私钥
  const privateKey = localSm2Decrypt(localStorage.getItem("privateKey"));
  let decryptData = value && sm2.doDecrypt(value, privateKey, 1);
  // 解密的数据
  return decryptData ? decryptData : null;
}
